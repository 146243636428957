/* purgecss start ignore */
@import "tailwindcss/base";
@import "tailwindcss/components";
/* purgecss end ignore */

@import "tailwindcss/utilities";

.raised-button {
  @apply px-4;
  @apply py-2;
  @apply border; 
  @apply border-transparent;
  @apply text-base;
  @apply leading-6;
  @apply font-medium;
  @apply rounded-md;
}

.raised-button-primary {
  @apply bg-indigo-800;
  @apply text-white;
}

.left-full {
  left: 100%;
}

.right-full {
  right: 100%;
}

.translate-y-1\/4 {
  --transform-translate-y: 25%;
}

.spinner {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: relative;
  width: 2rem;
  height: 2rem;
  
  & .path {
    stroke: #fff;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.no-show {
  opacity: 0;
  max-height: 0;
  visibility: hidden;
}

.show {
  opacity: 1;
  max-height: 5000px;
  visibility: visible;
}